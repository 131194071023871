import { countries } from './static/countries';
import { timezones } from './static/timezones';
import { ValidationErrors, FormGroup } from '@angular/forms';

export const API_ENDPOINT: any = [];

export const ROLES: any = {
  administrator: 'Administrator',
  developer: 'Developer',
  operator: 'Analyst',
  support: 'Support Specialist',
  read_only: 'View Only'
};

export const PRODUCT_VERSION = 2.1;

export const USER_TYPES: any = {
  admin: 'admin',
  iso: 'iso',
  agent: 'agent',
  merchant: 'merchant',
  normal: 'normal'
};

export const LOCAL_VAR: any = {
  selectedAgent: 'dowc_selectedAgent',
  selectedAgency: 'dowc_selectedAgency',
  credentialsKey: 'dowc_credentials'
};

export const DATE_TIME_FORMATE: any = {
  DEFAULT: 'yyyy/MM/dd H:mm:ss',
  DEFAULT12Hours: 'yyyy/MM/dd h:mm:ss'
};
export const DATE_FORMATE: any = {
  DEFAULT: 'yyyy/MM/dd',
  APIKEY: 'MMM dd, yyyy',
  INVOICE: 'MMM d, yyyy'
};
export const TIME_FORMATE: any = {
  DEFAULT: 'H:mm:ss'
};

export const COUNTRIES: Country.Object[] = countries;
export const TIMEZONES: Timezone.Object[] = timezones;

export const ERROR_OBJECTS: Object = {
  required: '{{field}} is required',
  email: 'Please enter valid email',
  min: 'Please enter value more than {{value}}',
  max: 'Please enter value not more than {{value}}',
  minlength: 'Please enter value with min length {{value}}',
  maxlength: 'Please enter value with max length {{value}}',
  pattern: 'Please enter valid value',
  ccNumber: 'Please enter valid card number'
};

export const TOASTR_CONFIG: any = {
  timeOut: 3500,
  positionClass: 'toast-top-center'
};

export const validations: any = {
  uri: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
};

// Validators
export const matchValidation: Function = (
  control1: string,
  control2: string
): Function => {
  return (control: FormGroup): ValidationErrors | null => {
    const mainControl = control.get(control1);
    const controlToMatch = control.get(control2);

    return mainControl.value !== controlToMatch.value
      ? { doesNotMatch: true }
      : null;
  };
};

export const REMITANCE_TYPES: any = {
  fr: 'FR',
  prdhi: 'PRDHI',
  prdhr: 'PRDHR',
  nr: 'NR'
};

export const REMITANCE_STYLES: any = {
  sr: 'SR',
  dr: 'DR'
};

export const REMITANCE_SUPPORT: any = {
  detailed: 'D',
  summary: 'S'
};

export const CANCELLATION_SUPPORT: any = {
  detailed: 'D',
  summary: 'S'
};

export const PAYMENT_TYPES: any = {
  check: 'C',
  ach: 'A'
};

export const DEALSTRUCTURE_SUBCATEGORY: any = {
  startFormation: 'SF',
  warehousePaperwork: 'WP'
};

export const THIRDPARTY_INTEGRATIONS: any = {
  pen: 'P',
  fiexpress: 'F',
  router1: 'R',
  dealerTrack: 'D',
  na: 'N',
  other: 'O'
};

export const DEALERSHIP_PERFORMANCE_REPORTING: any = {
  secureMetrics: 'SM',
  other: 'OT',
  nA: 'NA'
};

export const SYSTEM_USER_TYPES: any = {
  dealerAdministrator: 'DA',
  accounting: 'AC',
  fi: 'FI',
  serviceManager: 'SM',
  serviceWriter: 'SW'
};

export const STATES: any = [
  {
    name: 'Alabama',
    code: 'AL',
    _id: '1'
  },
  {
    name: 'Alaska',
    code: 'AK',
    _id: '2'
  },
  {
    name: 'Arizona',
    code: 'AZ',
    _id: '3'
  },
  {
    name: 'Arkansas',
    code: 'AR',
    _id: '4'
  },
  {
    name: 'California',
    code: 'CA',
    _id: '5'
  },
  {
    name: 'Colorado',
    code: 'CO',
    _id: '6'
  },
  {
    name: 'Connecticut',
    code: 'CT',
    _id: '7'
  },
  {
    name: 'Delaware',
    code: 'DE',
    _id: '8'
  },
  {
    name: 'Florida',
    code: 'FL',
    _id: '10'
  },
  {
    name: 'Georgia',
    code: 'GA',
    _id: '11'
  },
  {
    name: 'Hawaii',
    code: 'HI',
    _id: '12'
  },
  {
    name: 'Idaho',
    code: 'ID',
    _id: '13'
  },
  {
    name: 'Illinois',
    code: 'IL',
    _id: '14'
  },
  {
    name: 'Indiana',
    code: 'IN',
    _id: '15'
  },
  {
    name: 'Iowa',
    code: 'IA',
    _id: '16'
  },
  {
    name: 'Kansas',
    code: 'KS',
    _id: '17'
  },
  {
    name: 'Kentucky',
    code: 'KY',
    _id: '18'
  },
  {
    name: 'Louisiana',
    code: 'LA',
    _id: '19'
  },
  {
    name: 'Maine',
    code: 'ME',
    _id: '20'
  },
  {
    name: 'Maryland',
    code: 'MD',
    _id: '21'
  },
  {
    name: 'Massachusetts',
    code: 'MA',
    _id: '22'
  },
  {
    name: 'Michigan',
    code: 'MI',
    _id: '23'
  },
  {
    name: 'Minnesota',
    code: 'MN',
    _id: '24'
  },
  {
    name: 'Mississippi',
    code: 'MS',
    _id: '25'
  },
  {
    name: 'Missouri',
    code: 'MO',
    _id: '26'
  },
  {
    name: 'Montana',
    code: 'MT',
    _id: '27'
  },
  {
    name: 'Nebraska',
    code: 'NE',
    _id: '28'
  },
  {
    name: 'Nevada',
    code: 'NV',
    _id: '29'
  },
  {
    name: 'New Hampshire',
    code: 'NH',
    _id: '30'
  },
  {
    name: 'New Jersey',
    code: 'NJ',
    _id: '31'
  },
  {
    name: 'New Mexico',
    code: 'NM',
    _id: '32'
  },
  {
    name: 'New York',
    code: 'NY',
    _id: '33'
  },
  {
    name: 'North Carolina',
    code: 'NC',
    _id: '34'
  },
  {
    name: 'North Dakota',
    code: 'ND',
    _id: '35'
  },
  {
    name: 'Ohio',
    code: 'OH',
    _id: '36'
  },
  {
    name: 'Oklahoma',
    code: 'OK',
    _id: '37'
  },
  {
    name: 'Oregon',
    code: 'OR',
    _id: '38'
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
    _id: '39'
  },
  {
    name: 'Rhode Island',
    code: 'RI',
    _id: '40'
  },
  {
    name: 'South Carolina',
    code: 'SC',
    _id: '41'
  },
  {
    name: 'South Dakota',
    code: 'SD',
    _id: '42'
  },
  {
    name: 'Tennessee',
    code: 'TN',
    _id: '43'
  },
  {
    name: 'Texas',
    code: 'TX',
    _id: '44'
  },
  {
    name: 'Utah',
    code: 'UT',
    _id: '45'
  },
  {
    name: 'Vermont',
    code: 'VT',
    _id: '46'
  },
  {
    name: 'Virginia',
    code: 'VA',
    _id: '47'
  },
  {
    name: 'Washington D.C.',
    code: 'DC',
    _id: '48'
  },
  {
    name: 'Washington',
    code: 'WA',
    _id: '49'
  },
  {
    name: 'West Virginia',
    code: 'WV',
    _id: '50'
  },
  {
    name: 'Wisconsin',
    code: 'WI',
    _id: '51'
  },
  {
    name: 'Wyoming',
    code: 'WY',
    _id: '52'
  }
];

export const CONFIRMATION_MSGS: any = {
  switchAgent: {
    confirmation: {
      title: `Switch Agent Confirmation`,
      content: 'Are you sure want to switch agent?',
      okBtn: 'Switch Agent',
      cancelBtn: 'Cancel'
    }
  },
  agents: {
    deleteAgent: {
      title: `Delete Agent`,
      content: `Agent will no longer be able to access this account.`,
      okBtn: 'Delete Agent',
      cancelBtn: 'Cancel',
      service: 'AgentsService',
      method: 'deleteAgent'
    }
  },
  users: {
    deleteUser: {
      title: `Delete User`,
      content: `User will no longer be able to access this account.`,
      okBtn: 'Delete User',
      cancelBtn: 'Cancel',
      service: 'UsersService',
      method: 'deleteAgentUser'
    }
  },
  groups: {
    deleteGroup: {
      title: `Delete Group`,
      content: `Are you sure want to delete this group?`,
      okBtn: 'Delete Group',
      cancelBtn: 'Cancel',
      service: 'GroupsService',
      method: 'deleteAgentGroup'
    }
  },
  deals: {
    deleteDeal: {
      title: `Delete Deal`,
      content: `Are you sure want to delete this deal?`,
      okBtn: 'Delete Deal',
      cancelBtn: 'Cancel',
      service: 'DealsService',
      method: 'deleteDeal'
    },
    refreshPDF: {
      title: `Are you sure want to Regenerate?`,
      content: `If you regenerate the PDF, any signatures will be lost. The Agreement will be recreated with the information currently available for this deal.`,
      okBtn: 'Regenerate',
      cancelBtn: 'Cancel',
      service: 'DealsService',
      method: 'refreshPDF'
    }
  }
};
