<form [formGroup]="requestForm" (ngSubmit)="rejectRequest()">
  <div class="modal-header">
    <h4 class="modal-title">Share Request</h4>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <!-- <p class="col-12 mb-0">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
      </p> -->
    </div>
    <div class="form-group row">
      <div class="form-group col-12" *ngIf="commissionPDF==1">
        <label class="col-12 col-xl-12">
          <app-required-indication
            [control]="requestForm.get('shareComissionMail')"
            [label]="'Email to Share:'"
          ></app-required-indication>
        </label>
        <div class="col-12 col-xl-12">
          <app-form-error-wrapper
          [control]="requestForm.get('shareComissionMail')"
          [controlName]="'Email to Share'"
          [apiErrorType]="'shareComissionMail'"
        >
          <input
            type="email"
            name="company-email"
            class="form-control"
            formControlName="shareComissionMail"
          />
        </app-form-error-wrapper>
        </div>
      </div>
      <div class="form-group col-12"  *ngIf="commissionPDF!=1">
        <label class="col-12 col-xl-12">
          <app-required-indication
            [control]="requestForm.get('signator')"
            [label]="'Choose a Signator:'"
          ></app-required-indication>
        </label>
        <div class="col-12 col-xl-12">
          <app-form-error-wrapper
          [control]="requestForm.get('signator')"
          [controlName]="'Choose a Signator'"
          [apiErrorType]="'signator'"
        >
          <select class="custom-select" formControlName="signator">
            <option value="">Select a Contact</option>
            <option *ngFor="let ty of contacts" value="{{ ty }}">{{ty}}</option>
          </select>
        </app-form-error-wrapper>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button
      [disabled]="requestForm.invalid || isLoading"
      class="btn btn-primary"
      type="Share"
    >
      <app-button-loader [label]="'Share'" [isLoading]="isLoading"></app-button-loader>
    </button>
  </div>
</form>
