import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DealsService = /** @class */ (function () {
    function DealsService(httpClient) {
        this.httpClient = httpClient;
        this.dealData = null;
        this.dealData$ = new EventEmitter();
    }
    DealsService.prototype.getDeals = function (agentId) {
        return this.httpClient.get("/agent/" + agentId + "/deals").pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getAllDeals = function (userId) {
        return this.httpClient.get("/agent/" + userId + "/alldeals").pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getAnalyticsDealData = function (userId) {
        var params = null;
        return this.httpClient.get("/deal/" + userId + "/analytics", { params: params }).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getDealById = function (agentId, dealId) {
        var _this = this;
        this.httpClient
            .get("/agent/" + agentId + "/deal/" + dealId)
            .subscribe(function (body) {
            if (body) {
                _this.dealData = body.records;
                _this.dealData$.emit(_this.dealData);
            }
            else {
                _this.dealData = null;
            }
        });
    };
    DealsService.prototype.getDealDataById = function (agentId, dealId) {
        return this.httpClient.get("/agent/" + agentId + "/deal/" + dealId).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getDealDataDetails = function (agentId, dealId) {
        return this.httpClient.get("/agent/" + agentId + "/dealview/" + dealId).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getDealsByFilter = function (agentId, payload) {
        return this.httpClient
            .post("/agent/" + agentId + "/dealsbyfilter", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.deleteDeal = function (agentId, dealId) {
        return this.httpClient.delete("/agent/" + agentId + "/deal/" + dealId).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.buildDeal = function (agentId, dealId, payload) {
        return this.httpClient
            .put("/agent/" + agentId + "/deal/" + dealId + "/builddeal", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.acceptRequest = function (agentId, dealId, payload) {
        return this.httpClient
            .put("/agent/" + agentId + "/deal/" + dealId + "/acceptrequest", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.dealInfo = function (dealId) {
        return this.httpClient
            .get("/deal/" + dealId)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.rejectRequest = function (agentId, dealId, payload) {
        return this.httpClient
            .put("/agent/" + agentId + "/deal/" + dealId + "/rejectrequest", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.stepSubmit = function (userId, stepNo, payload, isAddMode) {
        if (stepNo === 1 && isAddMode) {
            return this.httpClient
                .post("/agent/" + userId + "/deal/step" + stepNo, payload)
                .pipe(map(function (body) {
                return body;
            }));
        }
        else {
            return this.httpClient
                .put("/agent/" + userId + "/deal/step" + stepNo, payload)
                .pipe(map(function (body) {
                return body;
            }));
        }
    };
    DealsService.prototype.updateAency = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/updatedealagency", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.changeRequest = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/changerequest", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getProductsByCategory = function (catName) {
        return this.httpClient.get("/productlist/" + catName).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getProductsarray = function (catName) {
        return this.httpClient.get("/productlist/" + catName).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getLendersList = function () {
        return this.httpClient.get("/lenderslist").pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getDealSelectedProducts = function (agentId, dealId) {
        return this.httpClient
            .get("/agent/" + agentId + "/deal/" + dealId + "/selectedproduct")
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getchangeRequestData = function (dealId, chageRequestId) {
        return this.httpClient
            .get("/deal/" + dealId + "/changerequest/" + chageRequestId)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.mergeChangeRequest = function (dealId, chageRequestId) {
        return this.httpClient
            .put("/deal/" + dealId + "/changerequest/" + chageRequestId + "/merge", {})
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.rejectChangeRequest = function (dealId, chageRequestId, payload) {
        return this.httpClient
            .put("/deal/" + dealId + "/changerequest/" + chageRequestId + "/reject", payload)
            .pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getChangeRequestList = function (dealId) {
        return this.httpClient.get("/deal/" + dealId + "/changerequestlist").pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.helprequest = function (helpFormdata) {
        return this.httpClient.put("/deal/helprequest", helpFormdata).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.changeStatus = function (dealId, payload) {
        return this.httpClient.put("/deal/" + dealId + "/changestatus", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.refreshPDF = function (data) {
        var payload = JSON.parse(data);
        return this.httpClient.post("/deal/" + payload[0] + "/refreshpdf", payload[1]).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.sharePDF = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/sharepdf", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.resendPDF = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/resendpdf", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.sendPDF = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/sendpdf", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.sendComPDF = function (dealId, payload) {
        return this.httpClient.post("/deal/" + dealId + "/sendCompdf", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getMailList = function (dealId) {
        return this.httpClient.get("/deal/" + dealId + "/mailhistory").pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.prototype.getSingedURL = function (url) {
        var payload = { url: url };
        return this.httpClient.post("/deal/getsignurl", payload).pipe(map(function (body) {
            return body;
        }));
    };
    DealsService.ngInjectableDef = i0.defineInjectable({ factory: function DealsService_Factory() { return new DealsService(i0.inject(i1.HttpClient)); }, token: DealsService, providedIn: "root" });
    return DealsService;
}());
export { DealsService };
