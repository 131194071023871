import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-old-product-preview',
  templateUrl: './old-product-preview.component.html',
  styleUrls: ['./old-product-preview.component.scss']
})
export class OldProductPreviewComponent implements OnInit {
  // oldProducts: any[] = [];
  @Input()
  oldProducts?= '';

  @Input()
  productVersion?= '';
  myproductversion=0;

  constructor(
    public activeModal: NgbActiveModal
  ) { 
    // console.log(this.oldProducts,'dddddddddddd');
  }
  test(){
    
  }
  
  ngOnInit() {
    console.log(this.oldProducts,'testtttttt')
    console.log(this.productVersion,'version');
    this.myproductversion=parseFloat(this.productVersion);
    // console.log(this.myproductversion,'version');
  }

}
