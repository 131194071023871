import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DealsService } from '@app/core/service/deals.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-mail',
  templateUrl: './request-mail.component.html',
  styleUrls: ['./request-mail.component.scss']
})
export class RequestMailComponent implements OnInit {
  @Input()
  dealId? = '';

  @Input()
  shareComissionMail? = '';

  @Input()
  signator? = '';

  @Input()
  commissionPDF? = 0;

  @Input()
  contactList? = '';
  contacts:any=['Primary Contact'];

  @Input()
  pdfs? = '';


  agent: any = null;
  requestForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    private dealsService: DealsService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.agent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    this.agent = JSON.parse(this.agent);
  }

  ngOnInit() {  
    console.log();  
    this.createForm();
  }

  createForm() {
    if(this.commissionPDF>0){
      this.requestForm = this.formBuilder.group({
        shareComissionMail: [this.shareComissionMail, [
          Validators.required,
          Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        ]],
        // signator: [this.signator, [
        //   Validators.required
        // ]]
      });
    } else {
      this.requestForm = this.formBuilder.group({
        // shareComissionMail: [this.shareComissionMail, [
        //   Validators.required,
        //   Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        // ]],
        signator: [this.signator, [
          Validators.required
        ]]
      });

    }
    if(this.contactList!=''){
      this.contacts=JSON.parse(this.contactList);
    }
  }

  rejectRequest() {
    this.isLoading=true;
    // let payload={dealerEmail:this.requestForm.value.dealerEmail,pdfs:this.pdfs};
    if(this.commissionPDF>0){
      let payload={shareComissionMail:this.requestForm.value.shareComissionMail};
        this.dealsService
          .sendComPDF(this.dealId,payload)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe((response: any) => {
            this.isLoading=false;
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          },
          (error: any) => {
            console.log(
              'Confirmation modal error:',
              error.error.validation.errors
            );
            this.isLoading = false;
          }
        );
    } else {

      let payload={signator:this.requestForm.value.signator,pdfs:this.pdfs};
        this.dealsService
          .sendPDF(this.dealId,payload)
          .subscribe((response: any) => {
            this.isLoading=false;
            if (response) {
              this.activeModal.close(response);
            } else {
              this.toastrService.warning('something went Wrong');
            }
          });
    }
    }
}
