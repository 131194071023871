import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@app/shared/breadcrumb';
import { ButtonLoaderComponent } from '@app/shared/button-loader';
import { NavigationComponent } from '@app/shared/header-navigation';
import { BlankComponent } from '@app/shared/layouts/blank';
import { FullComponent } from '@app/shared/layouts/full';
import { SafeHtmlPipe } from '@app/shared/pipes';
import { SidebarComponent } from '@app/shared/sidebar';
import { SpinnerComponent } from '@app/shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig
} from 'ngx-currency/src/currency-mask.config';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-toggle-switch';

import { RouteTransformerDirectiveDirective } from './directives';
import { FormErrorWrapperComponent } from './form-error-wrapper';
import { NoDataMsgComponent } from './no-data-msg';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import { TimeAgoPipe } from 'time-ago-pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { TreeviewModule } from 'ngx-treeview';
import { DealListComponent } from './deal-list/deal-list.component';
import { UserListComponent } from './user-list/user-list.component';
import { GroupListComponent } from './group-list/group-list.component';
import { ConfirmationComponent } from './modals/confirmation';
import { CreateGroupComponent } from './modals/create-group/create-group.component';
import { CreateUserComponent } from './modals/create-user/create-user.component';
import { RejectRequestComponent } from './modals/reject-request/reject-request.component';
import { CheckboxComponent } from './UI-components/checkbox/checkbox.component';
import { RadioComponent } from './UI-components/radio/radio.component';
import { MissedErrorsComponent } from './missed-errors/missed-errors.component';
import { NgxMaskModule } from 'ngx-mask';
import { Step1Component } from './steps/step1/step1.component';
import { Step2Component } from './steps/step2/step2.component';
import { Step3Component } from './steps/step3/step3.component';
import { Step4Component } from './steps/step4/step4.component';
import { Step5Component } from './steps/step5/step5.component';
import { Step6Component } from './steps/step6/step6.component';
import { Step7Component } from './steps/step7/step7.component';
import { Step8Component } from './steps/step8/step8.component';
import { Step9Component } from './steps/step9/step9.component';
import { Step10Component } from './steps/step10/step10.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PhonePipe } from './pipes/phone.pipe';
import { RequireIndicationComponent } from './required-indication';
import { DealHelpComponent } from './modals/deal-help/deal-help.component';
import { IpDetailComponent } from './modals/ip-detail/ip-detail.component';
import { OldProductPreviewComponent } from './modals/old-product-preview/old-product-preview.component';
import { RequestMailComponent } from './modals/request-mail/request-mail.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: false
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule.forRoot(),
    UiSwitchModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    NouisliderModule,
    TreeviewModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxPaginationModule
  ],
  declarations: [
    SpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    BlankComponent,
    FullComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    SafeHtmlPipe,
    NoDataMsgComponent,
    RouteTransformerDirectiveDirective,
    TableLoaderComponent,
    DealListComponent,
    UserListComponent,
    GroupListComponent,
    ConfirmationComponent,
    TimeAgoPipe,
    FilterPipe,
    PhonePipe,
    CreateGroupComponent,
    CreateUserComponent,
    RejectRequestComponent,
    CheckboxComponent,
    RadioComponent,
    MissedErrorsComponent,
    TableLoaderComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component,
    Step8Component,
    Step9Component,
    Step10Component,
    RequireIndicationComponent,
    DealHelpComponent,
    IpDetailComponent,
    OldProductPreviewComponent,
    RequestMailComponent
  ],
  exports: [
    SpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    BlankComponent,
    FullComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    SafeHtmlPipe,
    NoDataMsgComponent,
    RouteTransformerDirectiveDirective,
    TableLoaderComponent,
    DealListComponent,
    UserListComponent,
    GroupListComponent,
    ConfirmationComponent,
    TimeAgoPipe,
    PhonePipe,
    CreateGroupComponent,
    CreateUserComponent,
    RejectRequestComponent,
    CheckboxComponent,
    RadioComponent,
    MissedErrorsComponent,
    TableLoaderComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component,
    Step8Component,
    Step9Component,
    Step10Component,
    RequireIndicationComponent,
    DealHelpComponent,
    OldProductPreviewComponent,
    RequestMailComponent
  ],
  entryComponents: [
    ConfirmationComponent,
    CreateGroupComponent,
    CreateUserComponent,
    RejectRequestComponent,
    DealHelpComponent,
    IpDetailComponent,
    OldProductPreviewComponent,
    RequestMailComponent
  ], // Only Modal component included here
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    },
    SafeHtmlPipe
  ]
})
export class SharedModule {}
