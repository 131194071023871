<!-- <p>
  old-product-preview works!
</p>
<button type="button" class="btn btn-secondary" (click)="test()">
  Cancel
</button> -->
<div class="modal-header">
  <h4 class="modal-title" style="margin-top: 10px;">Old Products</h4>
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
    Close
  </button>
</div>
<div class="modal-body">
  <div class="product-preview-container" *ngIf="myproductversion > 2.4">
    <div *ngFor="let category of oldProducts; let c = index" class="preview-prod">
      <p class="text-primary">{{ category.name }}</p>
      <div *ngFor="let product of category.product; let i = index" class="preview-prod">
        <ng-container *ngIf="product.selection === true">
          <p class="preview-prod-lev1">{{ product.name }}</p>
          <ng-container *ngIf="product.options?.length > 0">
            <ng-container *ngFor="let op of product.options">
              <ng-container *ngIf="op.selection === true">
                <p class="preview-prod-lev2">
                  {{ op.name }}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
          <p class="preview-prod-lev2" *ngIf="product.partipations">Participating</p>
          <p class="preview-prod-lev2" *ngIf="product.nonpartipations">Non-Participating</p>
          <ng-container *ngIf="product.Children?.length > 0">
            <ng-container *ngFor="let itemlevel2 of product.Children">
              <p class="preview-prod-lev2 text-bold" *ngIf="itemlevel2.optionalTitle">
                {{ itemlevel2.optionalTitle }}
              </p>
              <ng-container *ngIf="itemlevel2.selection === true">
                <ng-container *ngIf="itemlevel2.optionalChildren?.length > 0">
                  <ng-container *ngFor="let optionalitemlevel3 of itemlevel2.optionalChildren">
                    <ng-container *ngIf="optionalitemlevel3.selection === true">
                      <p class="preview-prod-lev3">{{ optionalitemlevel3.name }}</p>
                        <ng-container *ngIf="product.Children?.length > 0">
                          <ng-container *ngFor="let ochil of product.Children">
                            <ng-container *ngIf="ochil.selection === true">
                              <p class="preview-prod-lev2">
                                {{ ochil.name }}
                              </p>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="product.shieldType?.length > 0">
            <ng-container *ngFor="let shield of product.shieldType">
              <ng-container *ngIf="shield.selection === true">
                <p class="preview-prod-lev2">{{ shield.name }}</p>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="product.addon?.length > 0">
            <ng-container *ngFor="let addon of product.addon">
              <ng-container *ngIf="addon.selection === true">
                <p class="preview-prod-lev2">
                  <span *ngIf="
                    product.name === 'Tire & Wheel Protection'
                  ">Cosmetic Wheel Protection Add-on: </span>{{ addon.name }}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="product-preview-container" *ngIf="!(myproductversion > 2.4)">
    <div *ngFor="let product of oldProducts; let i = index" class="preview-prod">
      <ng-container *ngIf="product.selection === true">
        <p class="preview-prod-lev1">{{ product.name }}</p>
        <ng-container *ngIf="product.children?.length > 0">
          <ng-container *ngFor="let itemlevel2 of product.children">
            <ng-container *ngIf="itemlevel2.selection === true">
              <p class="preview-prod-lev2" *ngIf="product.slug !== 'other'">
                {{ itemlevel2.name }}
              </p>
              <p class="preview-prod-lev2" *ngIf="product.slug === 'other'">
                {{ itemlevel2.value }}
              </p>
              <ng-container *ngIf="itemlevel2?.term != '' && itemlevel2?.slug != 'not-preload'">
                <p class="preview-prod-lev2">
                  <span *ngIf="
                    product.name === 'KeyFetch (Direct ONLY)'
                  ">Month:</span>
                  {{ itemlevel2.term }}
                </p>
              </ng-container>
              <!-- level2 optional children - begin -->
              <ng-container *ngIf="itemlevel2.optionalChildren?.length > 0">
                <ng-container *ngFor="let optionalitemlevel3 of itemlevel2.optionalChildren">
                  <ng-container *ngIf="optionalitemlevel3.selection === true">
                    <p class="preview-prod-lev3">{{ optionalitemlevel3.name }}</p>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- level2 optional children - end -->
              <ng-container *ngIf="itemlevel2.children?.length > 0">
                <ng-container *ngFor="let itemlevel3 of itemlevel2.children">
                  <ng-container *ngIf="itemlevel3.selection === true">
                    <p class="preview-prod-lev3">
                      <span *ngIf="product.slug === 'theftprotection'">PreLoad Amount: </span>{{ itemlevel3.name }}
                    </p>
                    <ng-container
                      *ngIf="itemlevel3?.term && itemlevel3?.term != '' && itemlevel2?.slug != 'not-preload'">
                      <p class="preview-prod-lev3">
                        <span *ngIf="
                          product.name === 'KeyFetch (Direct ONLY)'
                        ">Month:</span>
                        {{ itemlevel3.term }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="itemlevel3.children?.length > 0">
                      <ng-container *ngFor="
                        let itemlevel4 of itemlevel3.children
                      ">
                        <ng-container *ngIf="itemlevel4.selection === true">
                          <p class="preview-prod-lev4">
                            <span *ngIf="product.slug === 'theftprotection'">Upgrade Amount: </span>{{ itemlevel4.name
                            }}
                          </p>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="product.slug === '360ShieldVehicleProtectionProducts' && product.custermercarekit === true">
                <p class="preview-prod-lev4">
                  Would you like to order customer care kits?: Yes
                </p>
              </ng-container>
              <ng-container
                *ngIf="product.slug === '360ShieldVehicleProtectionProducts' && product.custermercarekit === false">
                <p class="preview-prod-lev4">
                  Would you like to order customer care kits?: No
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="product.shieldType?.length > 0">
          <ng-container *ngFor="let shield of product.shieldType">
            <ng-container *ngIf="shield.selection === true">
              <p class="preview-prod-lev2">{{ shield.name }}</p>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="product.addon?.length > 0">
          <ng-container *ngFor="let addon of product.addon">
            <ng-container *ngIf="addon.selection === true">
              <p class="preview-prod-lev2">
                <span *ngIf="
                  product.name === 'Tire & Wheel Protection'
                ">Cosmetic Wheel Protection Add-on: </span>{{ addon.name }}
              </p>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>