import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable, EventEmitter } from '@angular/core';
import { COUNTRIES, DATE_TIME_FORMATE, TIMEZONES } from '@app/core/constants';
import { FormGroup } from '@angular/forms';

@Injectable()
export class UtilService {
  datePipe = new DatePipe('en-US');
  stepClick = true;
  private _missedError = '';
  public missedErrors$ = new EventEmitter<string>();
  selectedProducts: any[] = [];
  $onProductChange: EventEmitter<any> = new EventEmitter<any>();
  public clickedOnStep$ = new EventEmitter<any>();
  constructor(private currencyPipe: CurrencyPipe) {}

  getCountries(available?: boolean): Country.Object[] {
    return COUNTRIES.filter(c =>
      available !== undefined ? c.available === available : true
    );
  }

  getTimeZones(): any[] {
    const timezones: any = [];
    TIMEZONES.map(t => {
      return t.utc.map(u => {
        if (u.indexOf('America') !== -1) {
          timezones.push({
            key: t.abbr,
            value: u
          });
        }
      });
    });
    return timezones;
  }

  // Use with (keydown) event
  restrictAplhabets(event: any, numberRestriction?: number) {
    const x = event.which || event.keyCode;

    if (event.ctrlKey || event.shiftKey || x === 9) {
      return true;
    }

    if (
      (x >= 48 && x <= 57) ||
      x === 8 ||
      (x >= 35 && x <= 40) ||
      x === 46 ||
      (x >= 96 && x <= 105) ||
      x === 86
    ) {
      return this.restrictInputLength(event, numberRestriction);
    } else {
      return false;
    }
  }

  // Use with (keydown) event
  restrictInputLength(event: any, restrictionNumber: number) {
    const x = event.which || event.keyCode;
    const value = event.target.value;
    const hasSelection = event.target.selectionStart !== value.length;
    const hasNavigationKeys =
      (x >= 35 && x <= 40) || event.ctrlKey || event.shiftKey;
    if (
      restrictionNumber &&
      value.length + 1 > restrictionNumber &&
      x !== 8 &&
      !hasSelection &&
      !hasNavigationKeys
    ) {
      return false;
    }
    return true;
  }

  dollarToCents(dollar: string | number) {
    return parseInt((parseFloat(dollar.toString()) * 100).toString(), 10);
  }

  centsToDollar(cents: string | number) {
    return parseFloat(cents.toString()) / 100;
  }

  removeWhiteSpace(obj: any): any {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        $.trim(obj[propName]) === '' ||
        (Array.isArray(obj[propName]) && !obj[propName].length) ||
        (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))
      ) {
        delete obj[propName];
      } else if (typeof obj[propName] != 'object') {
        obj[propName] = $.trim(obj[propName]);
      }
    }
    return obj;
  }

  removeEmpty(obj: any): any {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === '' ||
        (Array.isArray(obj[propName]) && !obj[propName].length) ||
        (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  isEmpty(obj: any): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  stringKeywordReplacer(msg: string, apiObject: any) {
    try {
      return msg.replace(/{([^}]+)}/g, (match, m1) => {
        if (m1.includes('$')) {
          return Math.abs(this.centsToDollar(this.get(apiObject, m1.slice(1))));
        }
        if (m1.includes('#')) {
          return this.datePipe.transform(
            this.get(apiObject, m1.slice(1)),
            DATE_TIME_FORMATE.APIKEY
          );
        }
        return this.get(apiObject, m1);
      });
    } catch (e) {
      console.log(e);
    }
    return msg;
  }

  objectResolver(path: string, obj: any) {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  get(obj: any, key: any): any {
    return key.split('.').reduce((o: any, x: any) => {
      return o === undefined || o === null ? o : o[x];
    }, obj);
  }

  generateWildcardObject(id: string): string {
    try {
      let object = '';
      const prefix = id.split('_')[0];

      object = JSON.stringify({
        $or: [
          {
            'data.object._id': id
          },
          {
            // ['data.object.' + INDENTIFIERS[prefix]]: id
          }
        ]
      });
      return object;
    } catch (e) {
      console.log(e);
    }
  }

  createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (const property in object) {
      if (
        !object.hasOwnProperty(property) ||
        object[property] === null ||
        object[property] === undefined
      ) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (
        typeof object[property] === 'object' &&
        !(object[property] instanceof File)
      ) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  setValidators(
    form: FormGroup,
    control: string,
    validators: any[]
  ): FormGroup {
    form.setValidators(validators);
    form.get(control).updateValueAndValidity();
    return form;
  }

  clearValidators(form: FormGroup, control: string): FormGroup {
    form.clearValidators();
    form.get(control).updateValueAndValidity();
    return form;
  }

  // For missed API Errors
  get missedErrors(): string | null {
    return this._missedError;
  }

  public setMissedError(errMsg: any) {
    this._missedError = errMsg;
    this.missedErrors$.emit(this._missedError);
    console.log(this.missedErrors, 'this.missedErrors EMIT');
  }

  public clearMissedError() {
    this._missedError = '';
    this.missedErrors$.emit(this._missedError);
  }

  showPaginationInfo(data: any, perPage: any, currentPage: any) {
    let lastPage = data.length / perPage;
    if (!this.isInt(lastPage)) {
      lastPage = Math.floor(lastPage) + 1;
    }
    if (data && data.length > 0) {
      const from = perPage * currentPage - perPage + 1;
      const to = perPage * currentPage;
      if (data.length % perPage === 0) {
        return `Showing ${from} to ${to} of ${data.length} entries`;
      } else {
        if (currentPage === lastPage) {
          return `Showing ${from} to ${(data.length % perPage) + from - 1} of ${
            data.length
          } entries`;
        } else {
          return `Showing ${from} to ${to} of ${data.length} entries`;
        }
      }
    } else {
      return '';
    }
  }

  showPaginationInfoNew(total: any, perPage: any, currentPage: any) {
    let lastPage = total / perPage;
    if (!this.isInt(lastPage)) {
      lastPage = Math.floor(lastPage) + 1;
    }
    if (total > 0) {
      const from = perPage * currentPage - perPage + 1;
      const to = perPage * currentPage;
      if (total % perPage === 0) {
        return `Showing ${from} to ${to} of ${total} entries`;
      } else {
        if (currentPage === lastPage) {
          return `Showing ${from} to ${(total % perPage) + from - 1} of ${
            total
            } entries`;
        } else {
          return `Showing ${from} to ${to} of ${total} entries`;
        }
      }
    } else {
      return '';
    }
  }

  isInt(n: any) {
    return n % 1 === 0;
  }

  getCustomDatepickerYears(type: any, limit: number) {
    const current = new Date();
    return {
      year:
        type === 'MIN'
          ? current.getFullYear() - limit
          : type === 'MAX'
          ? current.getFullYear() + limit
          : current.getFullYear(),
      month: current.getMonth(),
      day: current.getDate()
    };
  }

  get getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  get getCurrentMonth() {
    const currentDate = new Date();
    return currentDate.getMonth() + 1;
  }

  get getCurrentDay() {
    const currentDate = new Date();
    return currentDate.getDate();
  }

  getHiddenStep() {
    const elementArr = [];
    // if (
    //   document.getElementsByClassName('default navigable') &&
    //   document.getElementsByClassName('default navigable').length
    // ) {
    //   const elem = document.getElementsByClassName('default navigable');
    //   if (elem && elem.length) {
    //     for (let i = 0; i < elem.length; i++) {
    //       $(elem[i]).css('cursor', 'pointer');
    //       elementArr.push(elem[i]);
    //     }
    //   }
    // }
    // if (
    //   document.getElementsByClassName('default') &&
    //   document.getElementsByClassName('default').length
    // ) {
    //   const elem_default = document.getElementsByClassName('default');
    //   if (elem_default && elem_default.length) {
    //     for (let i = 0; i < elem_default.length; i++) {
    //       $(elem_default[i]).css('cursor', 'pointer');
    //       elementArr.push(elem_default[i]);
    //     }
    //   }
    // }

    // if (
    //   document.getElementsByClassName('done navigable') &&
    //   document.getElementsByClassName('done navigable').length
    // ) {
    //   const elem_done = document.getElementsByClassName('done navigable');
    //   if (elem_done && elem_done.length) {
    //     for (let i = 0; i < elem_done.length; i++) {
    //       $(elem_done[i]).css('cursor', 'pointer');
    //       elementArr.push(elem_done[i]);
    //     }
    //   }
    // }

    // if (
    //   document.getElementsByClassName('current') &&
    //   document.getElementsByClassName('current').length
    // ) {
    //   const elem_cur = document.getElementsByClassName('current');
    //   if (elem_cur && elem_cur.length) {
    //     for (let i = 0; i < elem_cur.length; i++) {
    //       elementArr.push(elem_cur[i]);
    //     }
    //   }
    // }

    // if (
    //   document.getElementsByClassName('done') &&
    //   document.getElementsByClassName('done').length
    // ) {
    //   const elem_done = document.getElementsByClassName('done');
    //   if (elem_done && elem_done.length) {
    //     for (let i = 0; i < elem_done.length; i++) {
    //       $(elem_done[i]).css('cursor', 'pointer');
    //       elementArr.push(elem_done[i]);
    //     }
    //   }
    // }
    if (
      $('.steps-indicator  li.done') &&
      $('.steps-indicator  li.done').length
    ) {
      const elem_done = $('.steps-indicator  li.done');
      if (elem_done && elem_done.length) {
        for (let i = 0; i < elem_done.length; i++) {
          $(elem_done[i]).css('cursor', 'pointer');
          elementArr.push(elem_done[i]);
        }
      }
    }
    this.hiddenStep(elementArr);    
  }

  hiddenStep(elementArr: any) {
    if (elementArr && elementArr.length) {
      for (let i = 0; i < elementArr.length; i++) {
        elementArr[i].addEventListener('click', (e: any) => {
          this.clickOnStep(e.target.textContent);
        });
      }
    } else {
      setTimeout(() => {
        this.getHiddenStep();
      }, 200);
    }
  }

  clickOnStep(stepTitle: any) {
    if (this.stepClick) {
      this.clickedOnStep$.emit(stepTitle);
      this.stepClick = false;
      setTimeout(() => {
        this.stepClick = true;
      }, 1000);
    }
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  checkAllClasss(step:any){
    const elem = $('.steps-indicator  li');
      if (elem && elem.length) {
        for (let i = 0; i < elem.length; i++) {   
          $(elem[i]).addClass('default');
          if(elem[i] && i==step){
            $(elem[i]).addClass('current');
          }
          // console.log("ED ",i,elem[i].className);
        }
      }
  }

  showStepAsDone(count: number) {
    const elementArr = [];
    if (
      $('.steps-indicator  li') &&
      $('.steps-indicator  li').length
    ) {
      const elem = $('.steps-indicator  li');
      if (elem && elem.length) {
        for (let i = 0; i < elem.length; i++) {
          if (i < count) {
            $(elem[i]).addClass('done');
          }
          elementArr.push(elem[i]);
        }
      }
    }
    // if (
    //   document.getElementsByClassName('default navigable') &&
    //   document.getElementsByClassName('default navigable').length
    // ) {
    //   console.log(count);
    //   const elem = document.getElementsByClassName('default navigable');
    //   if (elem && elem.length) {
    //     for (let i = 0; i < elem.length; i++) {
    //       if (i < count) {
    //         $(elem[i]).addClass('done');
    //       }
    //       elementArr.push(elem[i]);
    //     }
    //   }
    // }
    // if (
    //   document.getElementsByClassName('default') &&
    //   document.getElementsByClassName('default').length
    // ) {
    // const elem_default = document.getElementsByClassName('default');
    // if (elem_default && elem_default.length) {
    // for (let i = 0; i < elem_default.length; i++) {
    //   $(elem_default[i]).addClass('done');
    //   elementArr.push(elem_default[i]);
    // }
    // }
    // }
    this.notFoundStepAsDone(elementArr, count);
  }

  showStepDone(count: number) {
    const elementArr = [];
    if (
      document.getElementsByClassName('default') &&
      document.getElementsByClassName('default').length
    ) {
      const elem = document.getElementsByClassName('default');
      if (elem && elem.length) {
        for (let i = 0; i < elem.length; i++) {
          if (i < count) {
            $(elem[i]).addClass('done');
          }
          elementArr.push(elem[i]);
        }
      }
    }
    this.notFoundStepAsDone(elementArr, count);
  }

  notFoundStepAsDone(elementArr: any, count: number) {
    if (elementArr && elementArr.length === 0) {
      setTimeout(() => {
        this.showStepAsDone(count);
      }, 200);
    }
  }

  getStepIndexFromStepTitle(stepTitle: any) {
    let stepNumber = 0;
    // console.log(stepTitle);
    switch (stepTitle) {
      case 'Producer Information':
        stepNumber = 0;
        break;
      // case 'Additional Contact Information':
      //   stepNumber = 1;
      //   break;
      // case 'Private Accounting Contact':
      //   stepNumber = 1;
      //   break;
      case 'Technology Information':
        stepNumber = 1;
        break;
      case 'Billing Information':
        stepNumber = 2;
        break;
      case 'Products':
        stepNumber = 3;
        break;
      case 'Incentive (Pack) Information':
        stepNumber = 4;
        break;
      // case 'Technology Information':
      //   stepNumber = 4;
      //   break;
      case 'Commissions':
        stepNumber = 5;
        break;
      case 'Documents':
        stepNumber = 6;
        break;
      case 'Agreements':
        stepNumber = 7;
        break;
    }
    return stepNumber;
  }

  showCurrentEditableStep(step:number){
    // if (
    //   $('.steps-indicator  li') &&
    //   $('.steps-indicator  li').length
    // ) {
    //   const elem = $('.steps-indicator  li');
    //   if (elem && elem.length) {
    //     for (let i = 0; i < elem.length; i++) {
    //       if (i = step) {
    //         $(elem[i]).addClass('current');
    //       }
    //     }
    //   }
    // }
  }

  getDateFormate(data: any) {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const dt = date.getDate();
    const dat =
      (month < 10 ? '0' : '') +
      month +
      '/' +
      (dt < 10 ? '0' : '') +
      dt +
      '/' +
      year;
    return dat;
  }

  removeDate(formGroup: FormGroup, formControl: any) {
    formGroup.get(formControl).setValue(null);
    formGroup.get(formControl).markAsDirty();
  }

  getPageList(toalPage: number, currentPage: number) {
    let from = Math.floor(currentPage / 5) * 5;
    let to = (Math.floor(currentPage / 5) + 1) * 5;
    if (from == currentPage && from != 1) {
      from = from - 1;
      to = to - 1;
    }
    if (to > toalPage) {
      to = toalPage;
      from = to - 5;
    }
    from = (from < 1) ? 1 : from;
    // create an array of pages to ng-repeat in the pager control
    let pages: any = [];
    if (currentPage > 4 && from > 2) {
      pages.push({ page: 1, label: '1 .. ' });
    } else if (from == 2) {
      from = 1;
    }
    for (let i = from; i <= to; i++) {
      pages.push({ page: i, label: i });
    }
    if (toalPage > to) {
      pages.push({ page: toalPage, label: ((to + 1) == toalPage) ? toalPage : ' .. ' + toalPage });
    }
    return pages;
  }

  toggleDivClass(target:any,cls:any){
    if ($("#" + target).hasClass(cls)) {
      $("#" + target).removeClass(cls);
      $(".hidden-xl-up .fa-minus").removeClass("d-none");
      $(".hidden-xl-up .fa-plus").addClass("d-none");
    } else {
      $("#" + target).addClass(cls);
      $(".hidden-xl-up .fa-minus").addClass("d-none");
      $(".hidden-xl-up .fa-plus").removeClass("d-none");
    }
  }
}
