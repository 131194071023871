export const environment = {
  env: 'PROD',
  production: true,
  // serverBaseUrl: 'https://agent-api.herokuapp.com',
  //serverUrl: 'https://agent-api.herokuapp.com/v1'
  // serverBaseUrl: 'https://uat-pip-api.dowc.com',
  // serverUrl: 'https://uat-pip-api.dowc.com/v1',
  // s3Url: 'https://dowc-pipqa.s3.amazonaws.com',
  // siteKey: '6LcHBxAcAAAAAM5lFam36od_3C_HN4jsL8gxaejL',
  // mpdfUrl: 'https://devpdfr.dowc.com'
  serverBaseUrl: 'https://pip-api.dowc.com',
  serverUrl: 'https://pip-api.dowc.com/v1',
  s3Url: 'https://dowc-pip.s3.amazonaws.com',
  siteKey: '6LdoHzMcAAAAANhCct28ycJyWCI7f6INwlZIZdqr',
  mpdfUrl: 'http://127.0.0.1/mpdf'
};
